import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const getAll = async (environmentId: string): Promise<Dapp[]> => {
  const { data } = await apiWrapper(
    axios.get<Dapp[]>(
      buildConnectApiUrl(`/app/custodian/environments/${environmentId}/dapps`),
      requestConfig,
    ),
  )

  return data
}

export const getByID = async (
  environmentId: string,
  dappId: string,
): Promise<Dapp> => {
  const { data } = await apiWrapper(
    axios.get<Dapp>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/dapps/${dappId}`,
      ),
      requestConfig,
    ),
  )

  return data
}

export const getEnabled = async (environmentId: string): Promise<Dapp[]> => {
  const { data } = await apiWrapper(
    axios.get<Dapp[]>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/dapps/enabled`,
      ),
      requestConfig,
    ),
  )

  return data
}

export const getBlocked = async (environmentId: string): Promise<Dapp[]> => {
  const { data } = await apiWrapper(
    axios.get<Dapp[]>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/dapps/blocked`,
      ),
      requestConfig,
    ),
  )

  return data
}

export const bulkEnable = async (
  environmentId: string,
  data: Record<string, any>,
): Promise<Dapp[]> => {
  const { data: dapps } = await apiWrapper(
    axios.post<Dapp[]>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/dapps/enable/bulk`,
      ),
      data,
      requestConfig,
    ),
  )

  return dapps
}

export const bulkDisable = async (
  environmentId: string,
  data: Record<string, any>,
): Promise<Dapp[]> => {
  const { data: dapps } = await apiWrapper(
    axios.post<Dapp[]>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/dapps/disable/bulk`,
      ),
      data,
      requestConfig,
    ),
  )

  return dapps
}

export const bulkBlock = async (
  environmentId: string,
  data: Record<string, any>,
): Promise<Dapp[]> => {
  const { data: dapps } = await apiWrapper(
    axios.post<Dapp[]>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/dapps/block/bulk`,
      ),
      data,
      requestConfig,
    ),
  )

  return dapps
}

export default {
  getAll,
  getByID,
  getEnabled,
  getBlocked,
  bulkEnable,
  bulkDisable,
  bulkBlock,
}
