import { createContext, useContext } from 'react'

// Type.
type UserContextType = {
  user: UserData
  setUser: (user: UserData) => void
}

// Context.
const UserContext = createContext<UserContextType>(null)

// Provider.
export const UserProvider = UserContext.Provider

// Hook.
export const useUser = () => useContext(UserContext)

export default UserContext
