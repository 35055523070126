import * as React from 'react'

const HomeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="no-fill"
    fill="none"
    height={18}
    width={18}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m1.8 9 1.6-1.6m0 0L9 1.8l5.6 5.6m-11.2 0v8a.8.8 0 0 0 .8.8h2.4m8-8.8L16.2 9m-1.6-1.6v8a.8.8 0 0 1-.8.8h-2.4m-4.8 0a.8.8 0 0 0 .8-.8v-3.2a.8.8 0 0 1 .8-.8h1.6a.8.8 0 0 1 .8.8v3.2a.8.8 0 0 0 .8.8m-4.8 0h4.8"
      stroke="#393D44"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HomeIcon
