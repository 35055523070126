import styled from '@emotion/styled'

export const DropdownWrapper = styled.select(
  {
    border: 'none',
    cursor: 'pointer',
    padding: '10px 8px',
    position: 'relative',
    width: 'auto',

    '@media (max-width: 950px)': {
      width: '100%',
    },
  },
  ({ theme }) => ({
    borderRight: `8px solid ${theme.colors.backgrounds.greyLight}`,
    background: theme.isDark
      ? theme.colors.backgrounds.greyLight
      : theme.colors.backgrounds.greyLight,
    borderRadius: theme.borderRadius.default,
    color: theme.isDark ? theme.colors.white : theme.colors.grey,

    '&:focus': {
      outline: `2px solid ${theme.colors.primary}`,
    },
  }),
)
