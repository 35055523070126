import auth from '@lib/api/auth'
import custodian from '@lib/api/custodian'
import networks from '@lib/api/networks'
import users from '@lib/api/users'

export default {
  auth,
  custodian,
  networks,
  users,
}
