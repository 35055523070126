import { createContext, useContext } from 'react'

// Type.
type EnvironmentContextType = {
  environment: Environment
  setEnvironment: (environment: Environment) => void
}

// Context.
const EnvironmentContext = createContext<EnvironmentContextType | null>(null)

// Provider.
export const EnvironmentProvider = EnvironmentContext.Provider

// Hook.
export const useEnvironment = (): EnvironmentContextType =>
  useContext(EnvironmentContext)

export default EnvironmentContext
