import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const getAll = async (
  environmentId: string,
  queryParams: Record<string, any>,
): Promise<{
  data: MpcOperation[]
  meta: { total: number; currentCursor: string; nextCursor: string }
}> => {
  const { data } = await apiWrapper(
    axios.get<{
      data: MpcOperation[]
      meta: { total: number; currentCursor: string; nextCursor: string }
    }>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/mpc-operations`,
        queryParams,
      ),
      requestConfig,
    ),
  )

  return data
}

export default {
  getAll,
}
