import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import Cookies from 'js-cookie'

import { buildConnectApiUrl, requestConfig } from '@lib/api/requests'
import { removeAllCookies } from '@lib/cookies'

export const login = async (
  body: AuthBody,
): Promise<UserAuthData & { mfaRequired?: boolean }> => {
  const { data: user } = await axios.post<UserAuthData>(
    buildConnectApiUrl(`/app/auth/login`),
    body,
    requestConfig,
  )

  return user
}

export const logout = async (): Promise<void> => {
  // Revoke the session on the server side.
  await axios.post<null>(
    buildConnectApiUrl(`/app/auth/logout`),
    null,
    requestConfig,
  )

  removeAllCookies()

  return
}

export const magicLink = async (body: MagicLinkBody): Promise<void> => {
  try {
    await axios.post<MagicLinkResponse>(
      buildConnectApiUrl(`/app/auth/magic-link`),
      body,
      requestConfig,
    )
  } catch (error) {
    Sentry.captureException(error)

    if (error.response.status === 404) {
      throw new Error(error.response.data.error as string)
    }

    throw error
  }
}

export const configureTotp = async (): Promise<ConfigureTotpResponse> => {
  const { data } = await axios.post<ConfigureTotpResponse>(
    buildConnectApiUrl(`/app/auth/mfa/totp/configure`),
    null,
    requestConfig,
  )

  return data
}

export const authenticateTotp = async (
  body: AuthenticateTotpRequest,
): Promise<UserAuthData> => {
  const { data: user } = await axios.post<UserAuthData>(
    buildConnectApiUrl(`/app/auth/mfa/totp/authenticate`),
    body,
    requestConfig,
  )

  return user
}

export const authenticateRecoveryCode = async (
  body: AuthenticateTotpRequest,
): Promise<UserAuthData> => {
  const { data: user } = await axios.post<UserAuthData>(
    buildConnectApiUrl(`/app/auth/mfa/recovery-code/authenticate`),
    body,
    requestConfig,
  )

  return user
}

export const getRecoveryCodes = async (): Promise<string[]> => {
  const { data } = await axios.get<{ codes: string[] }>(
    buildConnectApiUrl(`/app/auth/mfa/recovery-code`),
    requestConfig,
  )

  return data.codes
}

export default {
  login,
  logout,
  magicLink,
  configureTotp,
  authenticateTotp,
  authenticateRecoveryCode,
  getRecoveryCodes,
}
