import styled from '@emotion/styled'

export const ContentWrapper = styled.div<ContentWrapperProps>(
  {
    position: 'relative',
    zIndex: 0,
    '@media (min-width: 951px)': {
      '&.menu-active': {
        '.sidebar-button': {
          right: '10px',
          top: '40px',

          '.arrow': {
            transform: 'rotate(-90deg) translate(0, 3px)',
          },
        },

        '.sidebar-wrapper': {
          borderRight: 'none',
          flexBasis: 0,
        },

        '& ~ .switch-toggle, .sidebar-links-box, #logo': {
          display: 'none',
        },
      },
    },

    '@media (max-width: 950px)': {
      '&.menu-active': {
        '.sidebar-links-box': {
          display: 'flex',
        },
      },
    },
  },
  ({ theme }) => ({
    backgroundColor: theme.isDark ? theme.colors.black : null,
  }),
)

export const DashboardContentSection = styled.section({
  width: '100%',
  '&:not(:first-of-type)': {
    marginTop: '4.5rem',
  },
})

export const DashboardContentSectionHeading = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
})

export const DashboardContentWrapper = styled.main({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '@media (max-width: 950px)': {
    paddingTop: '50px',
  },
})

export const DashboardContentAlert = styled.div(
  {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '5px',
    width: '100%',

    p: {
      fontSize: '0.7rem',
      margin: 0,
    },
  },
  ({ theme }) => ({
    backgroundColor: theme.isDark
      ? theme.colors.backgrounds.primaryLight
      : theme.colors.primary,
    color: theme.colors.white,
  }),
)

export const DashboardContentAlertEmpty = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: '23.5px',
  padding: '5px',
  width: '100%',
})

export const DashboardWrapper = styled.div(
  {
    display: 'flex',
    [`${DashboardContentSection} > ${DashboardContentSection}`]: {
      marginTop: '3rem',
      '&:not(:first-of-type)': {
        marginTop: '4.5rem',
      },
    },

    '@media (max-width: 950px)': {
      display: 'block',
    },
  },
  ({ theme }) => ({
    backgroundColor: theme.isDark ? theme.colors.black : null,
  }),
)
