import { AxiosRequestConfig } from 'axios'

export async function apiWrapper<T>(requestPromise: Promise<T>): Promise<T> {
  try {
    const response = await requestPromise
    return response
  } catch (error) {
    if (!error?.response) {
      throw error
    }

    const statusCode = error?.response?.status as number
    if (statusCode === 401 || statusCode === 403) {
      window.location.href = '/login'
      return
    }

    throw error
  }
}

export const requestConfig: AxiosRequestConfig<any> = {
  headers: { 'Cache-Control': 'no-cache' },
  withCredentials: true,
}

export const buildConnectApiUrl = (
  url: string,
  queryParams?: Record<string, any>,
) => {
  const urlWithQueryParams = `${url}${
    queryParams ? `?${new URLSearchParams(queryParams).toString()}` : ''
  }`

  if (!urlWithQueryParams.startsWith(process.env.CONNECT_API_URL)) {
    return `${process.env.CONNECT_API_URL}${urlWithQueryParams}`
  }

  return urlWithQueryParams
}
