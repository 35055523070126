import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

import environments from './environments'
import members from './members'

export const update = async (data: Record<string, any>): Promise<Dapp> => {
  const { data: dapp } = await apiWrapper(
    axios.put<Dapp>(buildConnectApiUrl(`/app/custodian`), data, requestConfig),
  )

  return dapp
}

export default {
  environments,
  members,
  update,
}
