import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const getAll = async (): Promise<Network[]> => {
  const { data: user } = await apiWrapper(
    axios.get<Network[]>(buildConnectApiUrl(`/app/networks`), requestConfig),
  )

  return user
}

export default {
  getAll,
}
