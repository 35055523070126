import * as React from 'react'

const BurgerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g stroke="#000" strokeLinecap="round" strokeWidth="2">
      <path d="M5 12h15M5 17h15M5 7h15"></path>
    </g>
  </svg>
)

export default BurgerIcon
