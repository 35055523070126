import * as React from 'react'

const SettingsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={18}
    width={18}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m16.15 6.222-1.46-.211a8.741 8.741 0 0 0-.317-.55l.55-1.411h.001c.23-.603-.006-1.28-.541-1.58l-.001-.001L11.838.964l-.002-.001a1.241 1.241 0 0 0-1.598.325h-.001l-.908 1.189a4.7 4.7 0 0 0-.317-.013c-.111 0-.216.006-.317.013l-.908-1.188A1.244 1.244 0 0 0 6.172.97L6.17.972 3.658 2.46h-.001c-.535.301-.78.977-.558 1.594v.003l.551 1.41c-.115.185-.22.37-.317.56l-1.443.21C1.262 6.318.8 6.865.8 7.511v2.997c0 .638.47 1.185 1.074 1.265l1.46.211c.097.183.2.367.316.55l-.558 1.419c-.231.595.006 1.272.54 1.573l2.521 1.497a1.22 1.22 0 0 0 1.618-.325l.908-1.188c.103.007.213.013.317.013.11 0 .215-.006.317-.013l.908 1.196c.38.495 1.051.625 1.614.319l.003-.002 2.505-1.481c.534-.3.78-.976.558-1.587v-.002l-.552-1.41c.116-.185.22-.37.318-.56l1.442-.21c.63-.08 1.09-.627 1.09-1.274V7.503c0-.645-.459-1.192-1.049-1.28Zm-2.74 1.4.021.053.057.008 1.93.287v2.063l-1.93.295-.057.008-.021.053-.174.43c-.147.372-.34.712-.596 1.054l-.277.364-.034.044.021.053.728 1.856-1.748 1.029-1.195-1.573-.035-.046-.057.007-.443.057h-.004c-.191.032-.4.047-.6.047-.199 0-.406-.023-.612-.047l-.443-.057-.057-.007-.035.046-1.195 1.572-1.74-1.036.72-1.848.02-.051-.032-.044-.26-.365H5.36a5.054 5.054 0 0 1-.612-1.064l-.165-.428-.021-.054-.058-.008-1.93-.287V7.97l1.93-.287.056-.008.022-.052.174-.422c.147-.364.348-.721.596-1.055l.276-.364.034-.044-.02-.053-.728-1.856 1.748-1.036 1.195 1.572.035.046.057-.007.442-.057c.199-.024.398-.047.605-.047.2 0 .385.021.584.045l.02.002.443.057.057.007.035-.046 1.195-1.573 1.748 1.03-.728 1.847-.02.052.033.044.268.364a5 5 0 0 1 .605 1.063l.174.43Zm-1.547 1.38c0-1.619-1.281-2.935-2.867-2.935S6.129 7.383 6.129 9.001c0 1.619 1.281 2.935 2.867 2.935S11.863 10.62 11.863 9Z"
      fill="#393D44"
      stroke="#393D44"
      strokeWidth={0.2}
    />
  </svg>
)

export default SettingsIcon
