import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const getProjects = async (
  environmentId: string,
): Promise<ZeroDevProject[]> => {
  const { data } = await apiWrapper(
    axios.get<ZeroDevProject[]>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/account-abstraction-projects`,
      ),
      { ...requestConfig, headers: { 'Cache-Control': 'no-cache' } },
    ),
  )

  return data
}

export const getProjectPolicies = async (
  environmentId: string,
  projectId: string,
): Promise<ZeroDevProjectPolicy[]> => {
  const { data: policies } = await apiWrapper(
    axios.get<ZeroDevProjectPolicy[]>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/account-abstraction-projects/${projectId}/policies`,
      ),
      requestConfig,
    ),
  )

  return policies
}

export const getProjectStatistics = async (
  environmentId: string,
  projectId: string,
  startDate: string,
  endDate: string,
): Promise<ZeroDevProjectStatistics> => {
  const { data: statistics } = await apiWrapper(
    axios.get<ZeroDevProjectStatistics>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/account-abstraction-projects/${projectId}/statistics`,
        { startDate, endDate },
      ),
      requestConfig,
    ),
  )

  return statistics
}

export const updateProjectPolicyGas = async (
  environmentId: string,
  projectId: string,
  policyId: number,
  value: number,
): Promise<ZeroDevProjectPolicy> => {
  const { data: policy } = await apiWrapper(
    axios.put<ZeroDevProjectPolicy>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/account-abstraction-projects/${projectId}/policies/${policyId}`,
      ),
      { value },
      requestConfig,
    ),
  )

  return policy
}

export default {
  getProjects,
  getProjectPolicies,
  getProjectStatistics,
  updateProjectPolicyGas,
}
