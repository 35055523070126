// Navigation events.
export const ExternalLinkClick = 'External Link Clicked'
export const NavItemClick = 'Nav Item Clicked'

// Modal events.
export const ModalOpened = 'Modal Opened'
export const ModalClosed = 'Modal Closed'

// Secret events.
export const SecretCopied = 'Secret Copied'
export const SecretUnmasked = 'Secret Unmasked'

// Environments events.
export const EnvironmentSelected = 'Environment Selected'

// Webhook request events.
export const WebhookRequestViewed = 'Webhook Request Viewed'

// Tooltip events.
export const TooltipOpened = 'Tooltip Opened'
export const TooltipClosed = 'Tooltip Closed'

export const CodeCopied = 'Code Copied'
