import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const getAll = async (
  environmentId: string,
  options: Record<string, any> = {},
): Promise<{
  data: AddressOnEnvironment[]
  meta: {
    limit: number
    offset: number
    total: number
  }
}> => {
  const { data } = await apiWrapper(
    axios.get<{
      data: AddressOnEnvironment[]
      meta: {
        limit: number
        offset: number
        total: number
      }
    }>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/address-on-environments`,
        options,
      ),
      { ...requestConfig, headers: { 'Cache-Control': 'no-cache' } },
    ),
  )

  return data
}

export const bulkBlock = async (
  environmentId: string,
  data: Record<string, any>,
): Promise<AddressOnEnvironment[]> => {
  const { data: addresses } = await apiWrapper(
    axios.post<AddressOnEnvironment[]>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/address-on-environments/block-bulk`,
      ),
      data,
      requestConfig,
    ),
  )

  return addresses
}

export const bulkUnblock = async (
  environmentId: string,
  data: Record<string, any>,
): Promise<AddressOnEnvironment[]> => {
  const { data: addresses } = await apiWrapper(
    axios.post<AddressOnEnvironment[]>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/address-on-environments/unblock-bulk`,
      ),
      data,
      requestConfig,
    ),
  )

  return addresses
}

export default {
  getAll,
  bulkBlock,
  bulkUnblock,
}
