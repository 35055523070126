import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const get = async (
  environmentId: string,
): Promise<WalletConnectMetadata> => {
  const { data } = await apiWrapper(
    axios.get<WalletConnectMetadata>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/wallet-connect-metadata`,
      ),
      requestConfig,
    ),
  )

  return data
}

export const create = async (
  environmentId: string,
  data: Record<string, any>,
): Promise<WalletConnectMetadata> => {
  const { data: metadata } = await apiWrapper(
    axios.post<WalletConnectMetadata>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/wallet-connect-metadata`,
      ),
      data,
      requestConfig,
    ),
  )

  return metadata
}

export const update = async (
  environmentId: string,
  data: Record<string, any>,
): Promise<WalletConnectMetadata> => {
  const { data: metadata } = await apiWrapper(
    axios.put<WalletConnectMetadata>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/wallet-connect-metadata`,
      ),
      data,
      requestConfig,
    ),
  )

  return metadata
}

export default {
  create,
  get,
  update,
}
