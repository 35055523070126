import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const getClientsCreated = async (
  environmentId: string,
  queryParams: Record<string, any>,
): Promise<InsightsResponse> => {
  const response = await apiWrapper(
    axios.get(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/insights/clients/created`,
        queryParams,
      ),
      requestConfig,
    ),
  )

  return response.data as InsightsResponse
}

export const getTotalMpcOperations = async (
  environmentId: string,
  queryParams: Record<string, any>,
): Promise<InsightsResponse> => {
  const response = await apiWrapper(
    axios.get(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/insights/mpc-operations/total`,
        queryParams,
      ),
      requestConfig,
    ),
  )

  return response.data as InsightsResponse
}

export const getTotalWallets = async (
  environmentId: string,
  queryParams: Record<string, any>,
): Promise<InsightsResponse> => {
  const response = await apiWrapper(
    axios.get(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/insights/wallets/total`,
        queryParams,
      ),
      requestConfig,
    ),
  )

  return response.data as InsightsResponse
}

export default {
  getClientsCreated,
  getTotalMpcOperations,
  getTotalWallets,
}
