import Cookies from 'js-cookie'

export const hasSessionCookie = (): boolean => {
  return Cookies.get('is_authed') === 'true'
}

export const hasIntermediateSessionCookie = (): boolean => {
  return Cookies.get('intermediate_auth') === 'true'
}

export const removeIntermediateSessionCookie = (): void => {
  Cookies.remove('intermediate_auth')
}

export const setSessionCookie = (): void => {
  const expires = new Date()
  expires.setDate(expires.getDate() + 1)

  Cookies.set('is_authed', 'true', {
    expires,
  })
}

export const setIntermediateSessionCookie = (): void => {
  const expiry = new Date()
  expiry.setMinutes(expiry.getMinutes() + 10)

  Cookies.set('intermediate_auth', 'true', { expires: expiry })
}

export const setMfaSkippedCookie = (): void => {
  const expiry = new Date()
  expiry.setDate(expiry.getDate() + 7)

  Cookies.set('mfa_skipped', 'true', { expires: expiry })
}

export const hasMfaSkippedCookie = (): boolean => {
  return Cookies.get('mfa_skipped') === 'true'
}

export const getUserMfaEnrolledCookie = (): string => {
  return Cookies.get('user_mfa_enrolled')
}

export const setUserMfaEnrolledCookie = (mfaEnrolled: boolean): void => {
  const expiry = new Date()
  expiry.setMinutes(expiry.getMinutes() + 10)

  Cookies.set('user_mfa_enrolled', String(mfaEnrolled), { expires: expiry })
}

export const setEnvironmentIdCookie = (environmentId: string): void => {
  Cookies.set('environmentId', environmentId)
}

export const getEnvironmentIdCookie = (): string => {
  return Cookies.get('environmentId')
}

export const removeEnvironmentIdCookie = (): void => {
  Cookies.remove('environmentId')
}

export const removeAllCookies = (): void => {
  Cookies.remove('environmentId')
  Cookies.remove('is_authed')
  Cookies.remove('intermediate_auth')
  Cookies.remove('user_mfa_enrolled')
}
