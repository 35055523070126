import * as React from 'react'

const FirewallIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.457 11.522a1 1 0 0 0 .293-.707v-5.63a1 1 0 0 0-.293-.707L11.522.543a1 1 0 0 0-.707-.293h-5.63a1 1 0 0 0-.707.293L.543 4.478a1 1 0 0 0-.293.707v5.63a1 1 0 0 0 .293.707l3.935 3.935a1 1 0 0 0 .707.293h5.63a1 1 0 0 0 .707-.293l3.935-3.935Zm-1.607-1.43a.8.8 0 0 1-.234.566l-2.958 2.958a.8.8 0 0 1-.566.234H5.908a.8.8 0 0 1-.566-.234l-2.958-2.958a.8.8 0 0 1-.234-.566V5.908a.8.8 0 0 1 .234-.566l2.958-2.958a.8.8 0 0 1 .566-.234h4.184a.8.8 0 0 1 .566.234l2.958 2.958a.8.8 0 0 1 .234.566v4.184Zm-2.762.996a.941.941 0 0 0 0-1.331L9.58 8.247a.35.35 0 0 1 0-.495l1.51-1.509a.941.941 0 1 0-1.332-1.331L8.247 6.42a.35.35 0 0 1-.494 0l-1.51-1.51a.941.941 0 0 0-1.331 1.332l1.51 1.51a.35.35 0 0 1 0 .494l-1.51 1.51a.941.941 0 1 0 1.331 1.331l1.51-1.51a.35.35 0 0 1 .495 0l1.509 1.51a.941.941 0 0 0 1.331 0Z"
      fill="#393D44"
      stroke="#393D44"
      strokeWidth={0.2}
    />
  </svg>
)

export default FirewallIcon
