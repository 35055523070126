import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const getAll = async (
  environmentId: string,
): Promise<CustodianApiKey[]> => {
  const { data } = await apiWrapper(
    axios.get<CustodianApiKey[]>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/api-keys`,
      ),
      requestConfig,
    ),
  )

  return data
}

export const create = async (
  environmentId: string,
  data: Record<string, any>,
): Promise<CustodianApiKey> => {
  const { data: apiKey } = await apiWrapper(
    axios.post<CustodianApiKey>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/api-keys`,
      ),
      data,
      requestConfig,
    ),
  )

  return apiKey
}

export const remove = async (apiKeyId: string): Promise<void> => {
  await apiWrapper(
    axios.delete(
      buildConnectApiUrl(`/app/custodian/api-keys/${apiKeyId}`),
      requestConfig,
    ),
  )
}

export default {
  create,
  getAll,
  remove,
}
