import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const getMe = async (): Promise<UserData> => {
  const response = await apiWrapper(
    axios.get<UserData>(buildConnectApiUrl(`/app/users/me`), requestConfig),
  )

  return response?.data
}

export const updateMe = async (data: Partial<UserData>): Promise<UserData> => {
  const response = await apiWrapper(
    axios.put<UserData>(
      buildConnectApiUrl(`/app/users/me`),
      data,
      requestConfig,
    ),
  )

  return response?.data
}

export default {
  getMe,
  updateMe,
}
