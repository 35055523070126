import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const getAll = async (): Promise<UserData[]> => {
  const { data: members } = await apiWrapper(
    axios.get<UserData[]>(
      buildConnectApiUrl(`/app/custodian/members`),
      requestConfig,
    ),
  )

  return members
}

export const invite = async (data: Record<string, any>): Promise<void> => {
  await apiWrapper(
    axios.post<UserData>(
      buildConnectApiUrl(`/app/custodian/members/invite`),
      data,
      requestConfig,
    ),
  )
}

export default {
  getAll,
  invite,
}
