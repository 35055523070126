import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

import webhookRequests from './webhookRequests'

export const get = async (
  environmentId: string,
  unmasked?: boolean,
): Promise<Webhook | null> => {
  const { data } = await apiWrapper(
    axios.get<Webhook | null>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/webhook${
          unmasked ? '?unmasked=true' : ''
        }`,
      ),
      requestConfig,
    ),
  )

  return data
}

export const createOrUpdate = async (
  environmentId: string,
  data: Record<string, any>,
): Promise<Webhook> => {
  const { data: webhook } = await apiWrapper(
    axios.post<Webhook>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/webhook`,
      ),
      data,
      requestConfig,
    ),
  )

  return webhook
}

export default {
  createOrUpdate,
  get,
  webhookRequests,
}
