import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

import accountAbstractionProjects from './accountAbstractionProjects'
import addressOnEnvironments from './addressOnEnvironments'
import apiKeys from './apiKeys'
import clients from './clients'
import dapps from './dapps'
import insights from './insights'
import mpcOperations from './mpcOperations'
import walletConnectMetadata from './walletConnectMetadata'
import webhook from './webhook'

export const getAll = async (): Promise<Environment[]> => {
  const { data: environments } = await apiWrapper(
    axios.get<Environment[]>(
      buildConnectApiUrl(`/app/custodian/environments`),
      requestConfig,
    ),
  )

  return environments
}

export const toggleEmailAlerts = async (
  environmentId: string,
): Promise<Environment> => {
  const { data: environment } = await apiWrapper(
    axios.put<Environment>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/toggle-email-webhook-alerts`,
      ),
      {},
      requestConfig,
    ),
  )

  return environment
}

export const enableMultiBackup = async (
  environmentId: string,
): Promise<Environment> => {
  const { data: environment } = await apiWrapper(
    axios.put<Environment>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/enable-multi-backup`,
      ),
      {},
      requestConfig,
    ),
  )

  return environment
}

export const toggleAdvancedSecurityScanning = async (
  environmentId: string,
  isBlockaidEnabled: boolean,
): Promise<Environment> => {
  const { data: environment } = await apiWrapper(
    axios.put<Environment>(
      buildConnectApiUrl(
        `/app/custodian/environments/${environmentId}/toggle-advanced-security-scanning`,
      ),
      {
        isBlockaidEnabled,
      },
      requestConfig,
    ),
  )

  return environment
}

export default {
  accountAbstractionProjects,
  addressOnEnvironments,
  apiKeys,
  clients,
  dapps,
  enableMultiBackup,
  getAll,
  insights,
  mpcOperations,
  toggleEmailAlerts,
  walletConnectMetadata,
  webhook,
  toggleAdvancedSecurityScanning,
}
